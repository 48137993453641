import React, { useContext, useEffect, useState } from 'react';
import * as styles from './Onboarding.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faAt, faBirthdayCake, faBusinessTime, faCheckDouble, faChevronDown, faGraduationCap, faHamburger, faHandshake, faPauseCircle, faPhone, faPlayCircle, faPlusCircle, faSave, faSignOutAlt, faSpinner, faStopCircle, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { EmployeeContext, OrganisationContext, UserContext } from '../../../Context/UserContext';
import { BankHolidays, CalendarEvent, EmergencyContact, Employee, HolidayEvent, Logs, Organisation, Reference, WorkDay, WorkLog } from '../../../Classes/Employee';
import { User as UserData } from '../../../Classes/User';
import Input from '../../../components/Input/input';
import { Loading } from '../../../components/Loading/Loading';
import { API_Organisation, API_SaveEmployee, API_SaveEmployeeContact, API_SaveReference, API_SaveWorkLog } from '../../../Services/ApiRoutes';
import { Fetch } from '../../../Services/Fetch';
import { Titles } from '../../../Data/Titles';
import { Genders } from '../../../Data/Genders';
import { Nationalities } from '../../../Data/Nationalities';
import { Licences } from '../../../Data/Licences';
import { faAddressCard, faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';
import { EmploymentStatus, WorkPlace, WorkStatus } from '../../../Enums/EmploymentStatus';
import { navigate } from 'gatsby';
import PortalEmployeeEvent from '../../Portal/Employees/Employee/Calendar/Event/Event';
import { DepartmentBlock, TeamBlock } from '../Hierarchy/Hierarchy';
import UnassignedTitle from '../../../components/UnassignedTitle/UnassignedTitle';

const EmployeePortalOnboarding: React.FC = () => {
  let defaultEvent: CalendarEvent = {
    Title: "",
    EndDate: "",
    StartDate: "",
    ID: 0,
    ApprovedBy: 0,
    isApproved: 0,
    Chat: "",
    CostPerUnit: "",
    EmployeeID: 0,
    Settings: "",
    Type: EmploymentStatus.ANNUAL_LEAVE,
    isPaid: false,
    SettingsJSON: [],
    OldChat: ""
  };
  const defaultRef: Reference = {
    ID: 0,
    Name: "",
    Email: "",
    Messages: "",
    Message: "",
    hasRead: false,
    isCharacter: false,
    Relationship: "",
    EmploymentStart: "",
    EmploymentEnd: "",
    Role: "",
    Performance: "",
    WouldReEmploy: false,
    NoReason: "",
    LeaveReason: "",
    AdditionalInformation: "",
    LengthOfKnowing: "",
    Character: "",
    Employee: {
      ID: 0,
      Personal: {
        Forename: "",
        Surname: ""
      }
    } as Employee
  } as Reference;
  const defaultContact: EmergencyContact = {
    ID: 0,
    EmployeeID: 0,
    Forename: "",
    Surname: "",
    Email: "",
    Phone: "",
    Relationship: ""
  } as EmergencyContact;
  const { Employee, setEmployee } = useContext(EmployeeContext);
  const { User, setUser, Language } = useContext(UserContext);
  const { Organisation, setOrganisation } = useContext(OrganisationContext);
  const [employee, setEmployeeInformation] = useState<Employee>({} as Employee);
  const [saving, setSaving] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const [time, setTime] = useState<string>(new Date().toLocaleTimeString());
  const [emergencyContact, setEmergencyContact] = useState<EmergencyContact>(defaultContact);
  const [reference, setReference] = useState<Reference>(defaultRef);
  const [send, setSend] = useState<boolean>(false);
  const [event, setEvent] = useState<CalendarEvent>(defaultEvent);
  const [referenceError, setReferenceError] = useState<string>("");

  const entitlement: number = 5.6;
  const maxEntitlement: number = 28;
  const days: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  useEffect(() => {
    if (Employee.ID) {
      setEmployeeInformation(JSON.parse(JSON.stringify(Employee)));
    }
  }, [Employee])

  useEffect(() => {
    if (User.ID) {
      if (!User.OnboardingComplete)
        GetOrganisation();
      else
        navigate("/Employee/Portal");
    }
  }, [User]);

  useEffect(() => {
    setReferenceError("");
    setReference(defaultRef);
    setEmergencyContact(defaultContact);
    setEvent(defaultEvent);
  }, [tab])

  useEffect(() => {
    if (Employee.ID) {
      let total: number = 0;

      if (Employee.Business.CurrentEmployment.WorkDays)
        JSON.parse(Employee.Business.CurrentEmployment.WorkDays).map(d => {
          total += d.Hours ? d.Hours : 0;
        })

      total = parseFloat(total.toFixed(1));
      setEmployee({ ...Employee, Business: { ...Employee.Business, CurrentEmployment: { ...Employee.Business.CurrentEmployment, ContractedHours: total.toFixed(1) } } });
    }
  }, [Employee.Business && Employee.Business.CurrentEmployment.WorkDays]);

  useEffect(() => {
    let theme = localStorage.getItem("THEME");

    if (theme) {
      document.body.classList.remove("DarkMode");
      document.body.classList.remove("LightMode");
      document.body.classList.add(theme);
    }
  }, [])

  useEffect(() => {
    if (User.ID) {
      const timer = setInterval(() => {
        setTime(new Date().toLocaleTimeString());
      }, 1000);
      return () => {
        clearInterval(timer);
      }
    }
  }, [User]);

  const GetOrganisation = async () => {
    await Fetch(`${API_Organisation}`, {
      CustomerID: User.CustomerID,
      CompanyID: User.Owner,
      PackageID: User.PackageID
    }).then(async (Response: Organisation) => {
      if (Response.Employees.length) {
        Response.BankHolidays = await GetBankHolidays();
        Response.Employees = Response.Employees.map((emp: Employee) => {
          var days = RemainingHolidays(`${new Date(emp.Business.CurrentEmployment.StartDate).getFullYear()}-01-01`, emp, Response.BankHolidays);
          var banks = !emp.Business.CurrentEmployment.worksBankHolidays ? (GetWorkedBankHolidays(`${new Date(emp.Business.CurrentEmployment.StartDate).getFullYear()}-01-01`, emp, Response.BankHolidays)).length : 0;
          emp.Business.HolidayEntitlement.StatutoryDays = (days + banks).toFixed()

          emp.CalendarEvents = emp.CalendarEvents.map(e => {
            e.SettingsJSON = JSON.parse(e.Settings);
            return e;
          })

          if (User.isEmployee && User.ID === emp.ID)
            setEmployee(emp);
          return emp;
        });
      }

      setOrganisation(Response);
    });
  }

  const DaysRemaining = (from: string, Employee: Employee) => {
    var startDate = new Date(from);
    var endOfYear = new Date(`${startDate.getFullYear() + 1}-01-01`);
    var daysOfYear: Date[] = [];
    for (var d = startDate; d <= endOfYear; d.setDate(d.getDate() + 1)) {
      var date = new Date(d);
      if ((Employee.Business.CurrentEmployment.WorkDays ? JSON.parse(Employee.Business.CurrentEmployment.WorkDays) as WorkDay[] : [] as WorkDay[]).find((x: WorkDay) => x.Day === date.getDay()))
        daysOfYear.push(date);
    }

    return daysOfYear.length ? Math.round(daysOfYear.length * 2) / 2 : 0;
  }

  const RemainingHolidays = (from: string, Employee: Employee, BankHolidays: BankHolidays[]) => {
    let perYear = (Employee.Business.CurrentEmployment.WorkDays ? JSON.parse(Employee.Business.CurrentEmployment.WorkDays) as WorkDay[] : [] as WorkDay[]).length * entitlement;
    perYear = perYear > maxEntitlement ? maxEntitlement : perYear;
    let remaining = Employee.Business.CurrentEmployment.WorkDays ? (perYear / 26) * (DaysRemaining(from, Employee) / ((JSON.parse(Employee.Business.CurrentEmployment.WorkDays) as WorkDay[]).length * 2)) : 0;
    remaining = remaining > maxEntitlement ? maxEntitlement : remaining;

    return remaining ? Math.round((remaining - (!Employee.Business.CurrentEmployment.worksBankHolidays ? GetWorkedBankHolidays(from, Employee, BankHolidays).length : 0)) * 10) / 10 : 0;
  }

  const GetWorkedBankHolidays = (from: string, Employee: Employee, BankHolidays: BankHolidays[]) => {
    let holidays: BankHolidays | undefined = BankHolidays.find(d => d.Year === new Date(from).getFullYear());
    if (holidays)
      return holidays.Days.filter(day => (Employee.Business.CurrentEmployment.WorkDays ? JSON.parse(Employee.Business.CurrentEmployment.WorkDays) as WorkDay[] : [] as WorkDay[]).find(x => x.Day === new Date(day.date).getDay()) && (new Date(day.date) >= new Date(from)))

    return [] as HolidayEvent[];
  }

  const GetBankHolidays = async () => {
    let holidays: BankHolidays[] = [] as BankHolidays[];

    await fetch("https://www.gov.uk/bank-holidays.json")
      .then((res) => res.json()).then((res) => {
        res["england-and-wales"]["events"].map((event: HolidayEvent) => {
          let index: number = holidays.findIndex(h => h.Year === new Date(event.date).getFullYear());
          if (index >= 0) {
            holidays[index].Days.push(event);
          } else {
            holidays.push({ Year: new Date(event.date).getFullYear(), Days: [event] as HolidayEvent[] } as BankHolidays);
          }
        });
      });

    return holidays;
  }

  const Save = async (e: any) => {
    e.preventDefault();
    setSaving(true);
    let skipSave: number[] = [0, 1, 5, 6, 7, 8, 9, 10]

    if (!skipSave.includes(tab)) {
      let empData: Employee = JSON.parse(JSON.stringify(employee));
      if (!empData.Personal.ValidLicence)
        empData.Personal.LicenceType = "";
      await Fetch(`${API_SaveEmployee}`, empData).then((emp: Employee) => {
        setEmployee(emp);
        setOrganisation({
          ...Organisation, Employees: Organisation.Employees.map((e) => {
            if (e.ID === emp.ID)
              return emp;
            return e;
          })
        })
        setTab(tab + 1);
      });
    } else
      setTab(tab + 1);

    setSaving(false);
  }

  const SaveAccount = async (Status: string, Place: string) => {
    let empData: Employee = JSON.parse(JSON.stringify(Employee));

    let log: WorkLog = {
      ID: 0,
      Date: new Date().toDateString(),
      StartTime: new Date().toLocaleTimeString(),
      EndTime: "",
      Status: Status,
      Place: Place,
      EmployeeID: User.ID,
      DoUpdate: true
    } as WorkLog

    if (empData.WorkLog.find(l => l.Date === new Date().toDateString())) {
      empData.WorkLog = empData.WorkLog.map(l => {
        if (l.Date === new Date().toDateString()) {
          l.Log[l.Log.length - 1].EndTime = log.StartTime;
          l.Log[l.Log.length - 1].DoUpdate = true;
          l.Log.push(log);
        }
        return l;
      })
    } else {
      empData.WorkLog.push({
        Date: new Date().toDateString(),
        Log: [log]
      } as Logs);
    }

    await Fetch(`${API_SaveWorkLog}`, empData.WorkLog).then((LogResponse: Logs[]) => {
      setEmployee({ ...Employee, Account: { ...Employee.Account, WorkPlace: Place, WorkStatus: Status }, WorkLog: LogResponse });
    });
  }

  const isDisabled = (ThisTab: number) => {
    switch (ThisTab) {
      case 0:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return false;
      case 1:
        return employee.Account.WorkStatus !== WorkStatus.TRAINING;
      case 2:
        return !employee.Personal.Forename || !employee.Personal.Surname || !employee.Personal.Title || !employee.Personal.Gender || !employee.Personal.Email || !employee.Personal.Phone || !employee.Personal.DateOfBirth || !employee.Personal.Nationality;
      case 3:
        return !employee.Personal.Address.AddressLine1 || !employee.Personal.Address.Country || !employee.Personal.Address.County || !employee.Personal.Address.Postcode || !employee.Personal.Address.TownCity
      case 4:
        return !employee.Personal.SortCode || !employee.Personal.AccountNumber || !employee.Business.NationalInsuranceNumber
      case 5:
        return (employee.EmergencyContacts ? employee.EmergencyContacts : []).length === 0
      default:
        return true;
    }
  }

  const LogOut = () => {
    localStorage.removeItem("User");
    localStorage.removeItem("sidebar1");
    localStorage.removeItem("sidebar2");
    setUser({} as UserData);
    setEmployee({} as Employee);
    setOrganisation({} as Organisation);
    navigate("/Login");
  }

  const FormatCurrency = (Cost: number) => {
    return new Intl.NumberFormat("en-GB").format(Cost).includes(".") ? new Intl.NumberFormat("en-GB").format(Cost) : new Intl.NumberFormat("en-GB").format(Cost) + ".00";
  }

  const SaveEmergencyContact = async () => {
    emergencyContact.EmployeeID = User.ID;
    if (!emergencyContact.ID)
      emergencyContact.ID = 0;

    if (emergencyContact.Forename && emergencyContact.Surname && emergencyContact.Phone && emergencyContact.Relationship) {
      await Fetch(`${API_SaveEmployeeContact}`, emergencyContact).then((emp: EmergencyContact) => {
        if (emp.ID) {
          let contacts: EmergencyContact[] = JSON.parse(JSON.stringify(Employee.EmergencyContacts));

          if (emergencyContact.ID === 0)
            contacts.push(emp);
          else {
            contacts = contacts.map(c => {
              if (c.ID === emp.ID)
                return emp;
              return c;
            })
          }

          setEmployee({ ...Employee, EmergencyContacts: contacts });
          setEmergencyContact(defaultContact);
        }
      });
    }
  }

  const Send = async () => {
    setSend(true);
    let messages: string[] = [] as string[];

    if (reference) {
      if (reference.Messages)
        messages = JSON.parse(reference.Messages);
      messages.push(reference.Message);

      reference.SentOn = new Date().toISOString();
      reference.Messages = JSON.stringify(messages);
      reference.isComplete = false;
      reference.hasRead = false;
      reference.Employee = Employee;

      await Fetch(`${API_SaveReference}`, reference).then((Ref: Reference) => {
        if (Ref.ID) {
          setReference(defaultRef);
          let refs: Reference[] = JSON.parse(JSON.stringify(Employee.References));
          if (reference && reference.ID) {
            refs = refs.map((r: Reference) => {
              if (reference && r.ID === reference.ID)
                r = Ref;
              return r;
            })
          } else {
            refs.push(Ref);
          }
          setEmployee({ ...Employee, References: refs });
        } else {
          setReferenceError("The reference request could not be sent to the provided email. Please try again later.")
        }
      })
    }

    setSend(false);
  }

  const GetManager = () => {
    return GetEmployeeData(Employee.Business.Department.Manager ? Employee.Business.Department.Manager.ID : 0);
  }

  const GetLeader = () => {
    return GetEmployeeData(Employee.Business.Team.Leader ? Employee.Business.Team.Leader.ID : 0);
  }

  const GetEmployeeData = (ID: number) => {
    return Organisation.Employees.find(e => e.ID === ID);
  }

  const FinishOnboarding = async () => {
    let empData: Employee = JSON.parse(JSON.stringify(employee));
    if (!empData.Personal.ValidLicence)
      empData.Personal.LicenceType = "";
    empData.OnboardingComplete = true;
    await Fetch(`${API_SaveEmployee}`, empData).then((emp: Employee) => {
      setEmployee(emp);
      setOrganisation({
        ...Organisation, Employees: Organisation.Employees.map((e) => {
          if (e.ID === emp.ID)
            return emp;
          return e;
        })
      })
      setUser({ ...User, OnboardingComplete: true });

      setTimeout(() => {
        navigate("/Employee/Portal")
      }, 2000)
    });
  }

  return (<div className={styles.Main}>
    <div className={styles.Header}>
      <button onClick={() => LogOut()}>
        Sign Out
        <i>
          <FontAwesomeIcon icon={faSignOutAlt} />
        </i>
      </button>
    </div>

    {!employee.ID ? <div className={styles.Loading}><Loading /></div> : <>
      <form autoComplete="off" className={styles.Form} onSubmit={(e) => Save(e)}>
        {tab === 0 ? <>
          <h1>Welcome To Your {Language.Applications.OnboardingWorkflow}</h1>
          <p>
            We want to thank you for joining our amazing Company!
          </p>
          <p>
            Before we begin, we will have to onboard you into the PrimeHR portal so you can request holidays and log your work time. We want to ensure you are fully prepared for your first day, so during this {Language.Applications.OnboardingWorkflow} process we will be having you:

            <hr />

            <ul>
              <li>Clock In for your first day
                <i>
                  <FontAwesomeIcon icon={isDisabled(1) ? faCircle : faCheckCircle} />
                </i>
              </li>
              <li>
                Add Your {Language.Application_Employees.PersonalInformation}
                <i>
                  <FontAwesomeIcon icon={isDisabled(2) ? faCircle : faCheckCircle} />
                </i>
              </li>
              <li>Add Your Address
                <i>
                  <FontAwesomeIcon icon={isDisabled(3) ? faCircle : faCheckCircle} />
                </i>
              </li>
              <li>Set up the account your salary will be paid into
                <i>
                  <FontAwesomeIcon icon={isDisabled(4) ? faCircle : faCheckCircle} />
                </i>
              </li>
              <li>Add one (or more) Emergency Contact(s)
                <i>
                  <FontAwesomeIcon icon={isDisabled(5) ? faCircle : faCheckCircle} />
                </i>
              </li>
              <li>Submit one (or more) Reference(s)
                <i>
                  <FontAwesomeIcon icon={isDisabled(6) ? faCircle : faCheckCircle} />
                </i>
              </li>
              <li>Request any upcoming holidays
              </li>
              <li>Understand your Role in the Company
              </li>
              <li>Get to know your Department
              </li>
              <li>Get to know  your Team
              </li>
              <li>Log in and get started
              </li>
            </ul>
          </p>
        </> : tab === 1 ? <>
          <h1>Clock In</h1>

          <p>The first thing we want you to do is get use to Clocking In every day. So to begin your first day with us we want you to click "Clock In" below.
            <hr />
          </p>

          <pre className={styles.Half}>
            On your PrimeHR Portal, this "Clock In" button can be found on the right hand side of the screen. The time shown is real-time based on your computer clock so make sure that is set correctly. This is to ensure that the time you clocked-in at is correct wherever you are in the world.
            <br />
            <br />
            Once you clock in, there is a collection of symbols below the "Clock Out" button and a dropdown box that allows you to select where you are currently working.
            <br />
            <br />
            <span>The symbols represent:</span>

            <span>
              <i>
                <FontAwesomeIcon icon={faHamburger} />
              </i>

              You are now on Lunch
            </span>

            <span>
              <i>
                <FontAwesomeIcon icon={faHandshake} />
              </i>

              You are in a Meeting
            </span>

            <span>
              <i>
                <FontAwesomeIcon icon={faGraduationCap} />
              </i>

              You are currently doing Training
            </span>

            <span>
              <i>
                <FontAwesomeIcon icon={faPauseCircle} />
              </i>

              You are on a Break
            </span>

            <span>
              <i>
                <FontAwesomeIcon icon={faBusinessTime} />
              </i>

              You are currently on Overtime
            </span>
          </pre>

          {Employee.Account ? <div className={styles.ClockOn}>
            <span>
              <b>{time.split(":")[0].split("")[0]}</b>
              <b>{time.split(":")[0].split("")[1]}</b>
              <i>:</i>
              <b>{time.split(":")[1].split("")[0]}</b>
              <b>{time.split(":")[1].split("")[1]}</b>
              <i>:</i>
              <b>{time.split(":")[2].split("")[0]}</b>
              <b>{time.split(":")[2].split("")[1]}</b>
            </span>

            <div className={styles.ClockOnInner}>
              {!Employee.Account.WorkStatus ? <button className={styles.Green} type="button" onClick={() => SaveAccount(WorkStatus.CLOCKED_IN, WorkPlace.IN_OFFICE)}>
                <i>
                  <FontAwesomeIcon icon={faPlayCircle} />
                </i>
                Clock In
              </button> : <button className={styles.Red} type="button" onClick={() => SaveAccount(WorkStatus.NOT_ACTIVE, "")}>
                <i>
                  <FontAwesomeIcon icon={faStopCircle} />
                </i>
                Clock Out
              </button>}

              {Employee.Account.WorkStatus ? <>
                <div className={styles.ClockOnOffice}>
                  <label><span>Working at:</span></label>
                  <select disabled={Employee.Account.WorkStatus === ""} onChange={(e) => SaveAccount(Employee.Account.WorkStatus, e.target.value)}>
                    <option>{WorkPlace.IN_OFFICE}</option>
                    <option>{WorkPlace.OUT_OF_OFFICE}</option>
                    <option>{WorkPlace.WORKING_FROM_HOME}</option>
                  </select>
                  <i>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </i>
                </div>

                <div className={styles.ClockOnOptions}>
                  <button type='button' title={WorkStatus.ON_LUNCH} onClick={(e) => SaveAccount(WorkStatus.ON_LUNCH, Employee.Account.WorkPlace)} className={WorkStatus.ON_LUNCH === Employee.Account.WorkStatus ? styles.Active : ""}>
                    <i>
                      <FontAwesomeIcon icon={faHamburger} />
                    </i>
                  </button>
                  <button type='button' title={WorkStatus.IN_MEETING} onClick={(e) => SaveAccount(WorkStatus.IN_MEETING, Employee.Account.WorkPlace)} className={WorkStatus.IN_MEETING === Employee.Account.WorkStatus ? styles.Active : ""}>
                    <i>
                      <FontAwesomeIcon icon={faHandshake} />
                    </i>
                  </button>
                  <button type='button' title={WorkStatus.TRAINING} onClick={(e) => SaveAccount(WorkStatus.TRAINING, Employee.Account.WorkPlace)} className={WorkStatus.IN_MEETING === Employee.Account.WorkStatus ? styles.Active : ""}>
                    <i>
                      <FontAwesomeIcon icon={faGraduationCap} />
                    </i>
                  </button>
                  <button type='button' title={WorkStatus.ON_BREAK} onClick={(e) => SaveAccount(WorkStatus.ON_BREAK, Employee.Account.WorkPlace)} className={WorkStatus.ON_BREAK === Employee.Account.WorkStatus ? styles.Active : ""}>
                    <i>
                      <FontAwesomeIcon icon={faPauseCircle} />
                    </i>
                  </button>
                  <button type='button' title={WorkStatus.OVERTIME} onClick={(e) => SaveAccount(WorkStatus.OVERTIME, Employee.Account.WorkPlace)} className={WorkStatus.OVERTIME === Employee.Account.WorkStatus ? styles.Active : ""}>
                    <i>
                      <FontAwesomeIcon icon={faBusinessTime} />
                    </i>
                  </button>
                </div>
              </> : <></>}

              {Employee.Account.WorkStatus && Employee.Account.WorkStatus !== WorkStatus.ACTIVE && Employee.Account.WorkStatus !== WorkStatus.CLOCKED_IN ? <div className={styles.ClockOnOverlay}>
                <span>
                  {Employee.Account.WorkStatus === WorkStatus.ON_LUNCH ? <FontAwesomeIcon icon={faHamburger} /> : <></>}
                  {Employee.Account.WorkStatus === WorkStatus.IN_MEETING ? <FontAwesomeIcon icon={faHandshake} /> : <></>}
                  {Employee.Account.WorkStatus === WorkStatus.ON_BREAK ? <FontAwesomeIcon icon={faPauseCircle} /> : <></>}
                  {Employee.Account.WorkStatus === WorkStatus.TRAINING ? <FontAwesomeIcon icon={faGraduationCap} /> : <></>}
                  <b>{Employee.Account.WorkStatus}</b>
                </span>

                <button type="button" onClick={() => SaveAccount(WorkStatus.ACTIVE, Employee.Account.WorkPlace)}>
                  <i>
                    <FontAwesomeIcon icon={faPlayCircle} />
                  </i>
                  Return To Work
                </button>
              </div> : <></>}
            </div>
          </div>
            : <></>}

          <p>To continue your {Language.Applications.OnboardingWorkflow}, set yourself to "In Training" then press "Save & Continue".</p>
        </> : tab === 2 ? <>
          <h1>{Language.Application_Employees.PersonalInformation}</h1>
          <p>
            Please fill in your own personal details so your employer can get to know you a little.
            <hr />
          </p>

          <Input Required Width="half" Type="text" Value={employee.Personal.Forename} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, Forename: v } })} Placeholder="..." Label={Language.Employee_Information.Forename} />
          <Input Required Width="half" Type="text" Value={employee.Personal.Surname} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, Surname: v } })} Placeholder="..." Label={Language.Employee_Information.Surname} />
          <Input Width="half" Required Type="select" Value={employee.Personal.Title} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, Title: v } })} Placeholder="..." Label={Language.Employee_Information.Title} Options={Titles} />
          <Input Width="half" Required Type="select" Value={employee.Personal.Gender} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, Gender: v as any } })} Placeholder="..." Label={Language.Employee_Information.Gender} Options={Genders} />
          <Input Required Type="email" Value={employee.Personal.Email} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, Email: v } })} Placeholder="..." Label={Language.Employee_Information.Email} Icon={faAt} />
          <Input Required Width="half" Type="text" Value={employee.Personal.Phone} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, Phone: v } })} Placeholder="..." Label={Language.Employee_Information.Phone} Icon={faPhone} />
          <Input Required Width="half" Type="date" Value={employee.Personal.DateOfBirth} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, DateOfBirth: v } })} Placeholder="..." Label={Language.Employee_Information.DateOfBirth} Icon={faBirthdayCake} />
          <Input Required Type="select" Value={employee.Personal.Nationality} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, Nationality: v } })} Placeholder="..." Label={Language.Employee_Information.Nationality} Options={Nationalities.map(n => {
            return {
              Text: n,
              Value: n
            }
          })} />

          <Input Type="checkbox" Value={employee.Personal.ValidLicence} OnChange={(v) => setEmployee({ ...employee, Personal: { ...employee.Personal, ValidLicence: v } })} Placeholder="..." Label="Do you have a valid Driving Licence?" />

          {Employee.Personal.ValidLicence ? <Input Required Type="select" Value={employee.Personal.LicenceType} OnChange={(v) => setEmployee({ ...employee, Personal: { ...employee.Personal, LicenceType: v as any } })} Placeholder="..." Label={Language.Employee_Information.LicenceType} Options={Licences.map((licence) => {
            return {
              Text: licence,
              Value: licence,
            }
          })} /> : <></>}

          <Input Type="checkbox" Value={employee.Personal.ValidPassport} OnChange={(v) => setEmployee({ ...employee, Personal: { ...employee.Personal, ValidPassport: v } })} Placeholder="..." Label="Do you have a valid Passport?" />
        </> : tab === 3 ? <>
          <h1>Your Address</h1>

          <p>We will need your address when we send you things by mail like for your pension, copy of your contract and anything else which may need a physical address to be sent to.
            <hr />
          </p>

          <div className={styles.Address}>
            <Input Required Type="text" Value={employee.Personal.Address.AddressLine1} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, Address: { ...employee.Personal.Address, AddressLine1: v } } })} Placeholder="..." Label={Language.Employee_Information.AddressLine1} />
            <Input Type="text" Value={employee.Personal.Address.AddressLine2} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, Address: { ...employee.Personal.Address, AddressLine2: v } } })} Placeholder="" Label={Language.Employee_Information.AddressLine2} />
            <Input Type="text" Value={employee.Personal.Address.AddressLine3} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, Address: { ...employee.Personal.Address, AddressLine3: v } } })} Placeholder="" Label={Language.Employee_Information.AddressLine3} />
            <Input Required Width="half" Type="text" Value={employee.Personal.Address.Postcode} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, Address: { ...employee.Personal.Address, Postcode: v } } })} Placeholder="..." Label={Language.Employee_Information.Postcode} />
            <Input Required Width="half" Type="text" Value={employee.Personal.Address.TownCity} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, Address: { ...employee.Personal.Address, TownCity: v } } })} Placeholder="..." Label={Language.Employee_Information.TownCity} />
            <Input Required Width="half" Type="text" Value={employee.Personal.Address.County} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, Address: { ...employee.Personal.Address, County: v } } })} Placeholder="..." Label={Language.Employee_Information.County} />
            <Input Required Width="half" Type="text" Value={employee.Personal.Address.Country} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, Address: { ...employee.Personal.Address, Country: v } } })} Placeholder="..." Label={Language.Employee_Information.Country} />
          </div>

        </> : tab === 4 ? <>
          <h1>Your Bank Details</h1>

          <p>In order for you to get paid, we need to make sure we have the correct Bank Account on file that you would like your salary to be paid into.
            <hr />
          </p>

          <div className={styles.Half}>
            <p>Fill in all required information and press "Save & Continue" to move onto the next section:</p>

            <Input Required Type="text" Value={employee.Business.NationalInsuranceNumber} OnChange={(v) => setEmployeeInformation({ ...employee, Business: { ...employee.Business, NationalInsuranceNumber: v } })} Placeholder="__ __ __ __ _" Label="National Insurance Number" />
            <Input Required Type="text" Value={employee.Personal.SortCode} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, SortCode: v } })} Placeholder="__ - __ - __" Label="Sort Code" />
            <Input Required Type="text" Value={employee.Personal.AccountNumber} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, AccountNumber: v } })} Placeholder="_ _ _ _ _ _ _ _" Label="Account Number" />

            <p>
              You are also contracted to work {Employee.Business.CurrentEmployment.ContractedHours} Hours per week. You can track those hours through your PrimeHR Portal.
            </p>
          </div>

          <pre className={styles.Half}>
            According to our records, your expected Salary before Tax will be £{FormatCurrency(parseInt(Employee.Business.CurrentEmployment.YearlyPay))}, if this information is incorrect then please inform your Line Manager so it can be corrected. Broken down your salary looks like:

            <ul>
              <li>
                <b>Yearly</b>
                £{FormatCurrency(parseInt(Employee.Business.CurrentEmployment.YearlyPay))}
              </li>

              <li>
                <b>Monthly</b>
                £{FormatCurrency(parseInt(Employee.Business.CurrentEmployment.MonthlyPay))}
              </li>

              <li>
                <b>Weekly</b>
                £{FormatCurrency(parseInt(Employee.Business.CurrentEmployment.WeeklyPay))}
              </li>

              <li>
                <b>Daily</b>
                £{FormatCurrency(parseInt(Employee.Business.CurrentEmployment.DailyPay))}
              </li>

              <li>
                <b>Hourly</b>
                £{FormatCurrency(parseInt(Employee.Business.CurrentEmployment.HourlyPay))}
              </li>
            </ul>

            <small>It isn't critical for you to know this information but its fun to know</small>
          </pre>
        </> : tab === 5 ? <>
          <h1>{Language.Application_Employees.EmergencyContacts}</h1>

          <p>In case of emergency, we would like to know who we need to contact in case you are unable to. Ideally we would like 2 emergency contacts but for now you can add one and add another from your PrimeHR Portal under "Accounts {`>`} {Language.Application_Employees.EmergencyContacts}"
            <hr />
          </p>

          <div className={`${styles.Address} ${styles.Half}`}>
            <Input Type="text" Value={emergencyContact.Forename} OnChange={(v) => setEmergencyContact({ ...emergencyContact, Forename: v })} Placeholder="..." Label="Forename" Icon={faAddressCard} />
            <Input Type="text" Value={emergencyContact.Surname} OnChange={(v) => setEmergencyContact({ ...emergencyContact, Surname: v })} Placeholder="..." Label="Surname" Icon={faAddressCard} />
            <Input Type="text" Value={emergencyContact.Relationship} OnChange={(v) => setEmergencyContact({ ...emergencyContact, Relationship: v })} Placeholder="e.g. Parent, Sibling, Partner" Label="Relationship To You" Icon={faUserFriends} />
            <Input Type="text" Value={emergencyContact.Phone} OnChange={(v) => setEmergencyContact({ ...emergencyContact, Phone: v })} Placeholder="..." Label="Phone Number" Icon={faPhone} />
            <Input Type="email" Value={emergencyContact.Email} OnChange={(v) => setEmergencyContact({ ...emergencyContact, Email: v })} Placeholder="..." Label="Email Address (optional)" Icon={faAt} />

            <button type="button" onClick={() => SaveEmergencyContact()}>
              <i>
                <FontAwesomeIcon icon={faPlusCircle} />
              </i>
              Add Contact
            </button>
          </div>

          <div className={styles.Contacts}>
            <h2>Your Contacts</h2>
            {
              employee.EmergencyContacts.map((e) => {
                return <div className={styles.Contact}>
                  <i>
                    <FontAwesomeIcon icon={faCheckDouble} />
                  </i>
                  <span>
                    <b>
                      {e.Forename} {e.Surname}
                      <small>({e.Relationship})</small>
                    </b>
                    {e.Email ? e.Email : ""}
                  </span>
                  {e.Phone}
                </div>
              })
            }
          </div>

          <pre>
            Your Emergency Contacts will be our first point of call if you are ever in an accident so make sure you keep these up to date with the right contact information.
          </pre>
        </> : tab === 6 ? <>
          <h1>References</h1>

          <p>
            To get an idea of who you are, we would like to contact previous Employers to give us a reference into how you work or possibly a character reference to help us understand you as a person
            <hr />
          </p>

          <div className={`${styles.Address} ${styles.Half}`}>
            <Input Type="text" Value={reference.Name} OnChange={(v) => setReference({ ...reference, Name: v })} Placeholder="..." Label="Referrer Name" Icon={faAddressCard} />
            <Input Type="email" Value={reference.Email} OnChange={(v) => setReference({ ...reference, Email: v })} Label="Referrer Email" Icon={faAt} Placeholder="..." />
            <Input Type="textarea" Value={reference.Message} OnChange={(v) => setReference({ ...reference, Message: v })} Placeholder="..." Label="Message" />

            {referenceError ? <div className={styles.Error}>{referenceError}</div> : <></>}

            <button disabled={send} type="button" onClick={() => Send()}>
              <i>
                <FontAwesomeIcon icon={send ? faSpinner : faPlusCircle} spin={send} />
              </i>
              {send ? "Sending..." : "Send Request"}
            </button>
          </div>

          <div className={styles.Contacts}>
            <h2>Your Requests</h2>
            {
              employee.References.map((e) => {
                return <div className={styles.Contact}>
                  <i>
                    <FontAwesomeIcon icon={faCheckDouble} />
                  </i>
                  <span>
                    <b>
                      {e.Name}
                    </b>
                    {e.Email ? e.Email : ""}
                  </span>
                </div>
              })
            }
          </div>

          <pre>
            When you send your references, the recipient will receive and email that links them to a form to fill in. Once you are happy with your requests, press "Save & Continue" to move on.
          </pre>
        </> : tab === 7 ? <>
          <h1>Holidays</h1>

          <p>
            If you have any outstanding Holidays booked that we need to know about then submit them now. When you submit a "Leave Request" your Line Manager will receive it for approval.
            <hr />
          </p>

          <div className={`${styles.Half}`}>
            <PortalEmployeeEvent FormOnly={true} Show={true} Close={() => { setEvent(defaultEvent); }} Event={event} setEvent={(e) => setEvent(e)} Employee={employee} setEmployee={(e) => setEmployee(e)} />
          </div>

          <pre className={styles.Half}>
            According to our records, you will be entitled to {Employee.Business.HolidayEntitlement.StatutoryDays} days{parseFloat(Employee.Business.HolidayEntitlement.ExtraDays) ? ` with an additional ${Employee.Business.HolidayEntitlement.ExtraDays} days` : ""} per year.

            Your working days each week will be:
            <ul>
              {Employee.Business.CurrentEmployment.WorkDays ? JSON.parse(Employee.Business.CurrentEmployment.WorkDays).map((day: WorkDay) => {
                return <li><b>{days[day.Day]}</b>{day.Hours} Hours</li>
              }) : <></>}
            </ul>
            <br />
            {Employee.Business.CurrentEmployment.worksBankHolidays ? "You will be expected to work Bank Holidays." : "You will not be expected to work Bank Holidays."}
            <br />
            <br />
            This years remaining Bank Holidays are:
            <ul>
              {Organisation.BankHolidays.filter(b => b.Year === new Date().getFullYear()).map((b => {
                return b.Days.filter(d => +new Date(d.date) >= +new Date()).map((d) => {
                  return <li><b>{d.title}</b>{new Date(d.date).toLocaleDateString(window.navigator.language)}</li>
                })
              }))}
            </ul>
            <br />
            <br />
            All this information is available to view from your PrimeHR Portal. All absences can be seen on the "Company Calendar" and your own absences can be seen on your Dashboard Calendar.
          </pre>
        </> : tab === 8 ? <>
          <h1>Your Role</h1>

          <p>
            We want to make sure you understand your role here on your first day. This way you can start to help us build our business as quickly as possible.
            <hr />
          </p>

          <pre className={styles.Half}>
            {Employee.Business.CurrentEmployment.Description}
          </pre>

          <pre className={styles.Half}>
            If you need any help during your day, make sure to get in touch with your Line Manager or Team Leader:
            <br />
            <br />
            {User.isEmployee && Employee && Employee.Business && Organisation.Employees ?
              GetLeader() && GetManager() && GetManager()?.ID === GetLeader()?.ID ? GetManager()?.ID === User.ID ? <></> : <>
                <div className={styles.Manager}>
                  <b>Manager & Team Leader</b>

                  <div className={styles.ManagerImage} style={{ backgroundImage: `url(${GetManager()?.Personal.Image})` }}>
                    {
                      GetManager()?.Personal.Image ? <></> : <h1>{GetManager()?.Personal.Forename.split("")[0]}{GetManager()?.Personal.Surname.split("")[0]}</h1>
                    }
                  </div>

                  <div className={styles.ManagerInfo}>
                    <h3>{GetManager()?.Personal.Forename} {GetManager()?.Personal.Surname}</h3>
                    <small>
                      {GetManager()?.Business.CurrentEmployment.JobTitle ? GetManager()?.Business.CurrentEmployment.JobTitle : <UnassignedTitle />}
                    </small>
                  </div>
                </div>
              </> : <>
                {GetManager() && GetManager()?.ID !== User.ID ? <div className={styles.Manager}>
                  <b>Manager</b>

                  <div className={styles.ManagerImage} style={{ backgroundImage: `url(${GetManager()?.Personal.Image})` }}>
                    {
                      GetManager()?.Personal.Image ? <></> : <h1>{GetManager()?.Personal.Forename.split("")[0]}{GetManager()?.Personal.Surname.split("")[0]}</h1>
                    }
                  </div>

                  <div className={styles.ManagerInfo}>
                    <h3>{GetManager()?.Personal.Forename} {GetManager()?.Personal.Surname}</h3>
                    <small>
                      {GetManager()?.Business.CurrentEmployment.JobTitle ? GetManager()?.Business.CurrentEmployment.JobTitle : <UnassignedTitle />}
                    </small>
                  </div>
                </div> : <></>}
                {GetLeader() && GetLeader()?.ID !== User.ID ? <div className={styles.Manager}>
                  <b>Team Leader</b>

                  <div className={styles.ManagerImage} style={{ backgroundImage: `url(${GetLeader()?.Personal.Image})` }}>
                    {
                      GetLeader()?.Personal.Image ? <></> : <h1>{GetLeader()?.Personal.Forename.split("")[0]}{GetLeader()?.Personal.Surname.split("")[0]}</h1>
                    }
                  </div>

                  <div className={styles.ManagerInfo}>
                    <h3>{GetLeader()?.Personal.Forename} {GetLeader()?.Personal.Surname}</h3>
                    <small>
                      {GetLeader()?.Business.CurrentEmployment.JobTitle ? GetLeader()?.Business.CurrentEmployment.JobTitle : <UnassignedTitle />}
                    </small>
                  </div>
                </div> : <></>}
              </> : <></>}
          </pre>
        </> : tab === 9 ? <>
          <h1>Your Department</h1>

          <p>
            Start to get to know the people in your Department. Departments can be further split into Teams. You may belong to one of these Teams so get to know these people around you, they will be your best point of call if you have questions about your work.
            <hr />
          </p>

          <div className={styles.Hierarchy}>
            {Organisation.Departments.find(d => d.ID === Employee.Business.Department.ID) ? Organisation.Departments.filter(d => d.ID === Employee.Business.Department.ID).map(d => {
              return <DepartmentBlock Employees={Organisation.Employees} Teams={Organisation.Teams} Departments={Organisation.Departments} Department={d} />
            }) : <div className={styles.Message}>
              <h2>
                Looks like you haven't been assigned to a Department yet!
                <small>Feel free to skip this section by pressing <b>"Save & Continue"</b></small>
              </h2>
            </div>}
          </div>
        </> : tab === 10 ? <>
          <h1>Your Team</h1>

          <p>
            Your Team will be the people you work closest with. Spend some time shadowing and getting to know them to see what type of people you are working with and what things they are working on.
            <hr />
          </p>

          <div className={styles.Hierarchy}>
            {Organisation.Teams.find(d => d.ID === Employee.Business.Team.ID) ? Organisation.Teams.filter(d => d.ID === Employee.Business.Team.ID).map(t => {
              return <TeamBlock Employees={Organisation.Employees} Teams={Organisation.Teams} Departments={Organisation.Departments} Team={t} />
            }) : <div className={styles.Message}>
              <h2>
                Looks like you haven't been assigned to a Team yet!
                <small>Feel free to skip this section by pressing <b>"Save & Continue"</b></small>
              </h2>
            </div>}
          </div>
        </> : tab === 11 ? <>
          <h1>All Done!</h1>

          <p>
            We have gathered all the information we need in order for you to begin your employment here with us.
          </p>

          <p>Your Probation Period lasts until {new Date(Employee.Business.Probation.ProbationEndDate).toDateString()}. Once complete, you will be able to access all our benefits and pension scheme.

            <hr />
          </p>

          <div className={styles.Complete}>
            <button type="button" onClick={() => FinishOnboarding()}>
              <i>
                <FontAwesomeIcon icon={faCheckDouble} />
              </i>
              Finsh Your {Language.Applications.OnboardingWorkflow}
            </button>
          </div>
        </> : <></>}

        <div className={styles.Buttons}>
          {tab < 11 ? <>
            <button disabled={isDisabled(tab) || saving} type="submit">
              {tab ? <i>
                <FontAwesomeIcon icon={saving ? faSpinner : faSave} spin={saving} />
              </i> : <></>}
              {saving ? "Saving..." : tab ? "Save & Continue" : "Let's Begin"}
            </button>

            {tab ? <button onClick={() => setTab(tab - 1)} type="button" className={styles.Link}>
              <i>
                <FontAwesomeIcon icon={faArrowLeft} />
              </i>
              Previous
            </button> : <></>}
          </> : <></>}
        </div>
      </form>
    </>}
  </div >);
};

export default EmployeePortalOnboarding;
