// extracted by mini-css-extract-plugin
export var Active = "Onboarding-module--Active--ba596";
export var Address = "Onboarding-module--Address--88f27";
export var Buttons = "Onboarding-module--Buttons--25def";
export var ClockOn = "Onboarding-module--ClockOn--69970";
export var ClockOnInner = "Onboarding-module--ClockOnInner--a8098";
export var ClockOnOffice = "Onboarding-module--ClockOnOffice--33978";
export var ClockOnOptions = "Onboarding-module--ClockOnOptions--0982e";
export var ClockOnOverlay = "Onboarding-module--ClockOnOverlay--b3829";
export var Complete = "Onboarding-module--Complete--34938";
export var Contact = "Onboarding-module--Contact--3092e";
export var Contacts = "Onboarding-module--Contacts--e551f";
export var Error = "Onboarding-module--Error--b847a";
export var Green = "Onboarding-module--Green--96a12";
export var Half = "Onboarding-module--Half--5c185";
export var Header = "Onboarding-module--Header--73502";
export var Hierarchy = "Onboarding-module--Hierarchy--b52ed";
export var Link = "Onboarding-module--Link--d0769";
export var Loading = "Onboarding-module--Loading--2c178";
export var Main = "Onboarding-module--Main--03aef";
export var Manager = "Onboarding-module--Manager--b6bd9";
export var ManagerImage = "Onboarding-module--ManagerImage--45803";
export var ManagerInfo = "Onboarding-module--ManagerInfo--3ebf4";
export var Message = "Onboarding-module--Message--5e3b9";
export var PackageImage = "Onboarding-module--PackageImage--03448";
export var Red = "Onboarding-module--Red--43957";