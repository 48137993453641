// extracted by mini-css-extract-plugin
export var Modal = "Modal-module--Modal--80335";
export var ModalBackground = "Modal-module--ModalBackground--0a7bd";
export var ModalBody = "Modal-module--ModalBody--bb046";
export var ModalContent = "Modal-module--ModalContent--5bb34";
export var ModalFooter = "Modal-module--ModalFooter--d9996";
export var ModalHeader = "Modal-module--ModalHeader--87187";
export var Show = "Modal-module--Show--cccd1";
export var large = "Modal-module--large--da87e";
export var medium = "Modal-module--medium--89337";
export var small = "Modal-module--small--a5deb";