import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout/layout';
import EmployeePortalOnboarding from '../../Views/EmployeePortal/Onboarding/Onboarding';

const OnboardingPage: React.FC = () => {
    return (
        <Layout NoFooter={true} NoHeader={true}>
            <Helmet htmlAttributes={{ lang: 'en' }}>
                <title>PrimeHR :: Onboarding</title><meta charSet='utf-8' />
            </Helmet>
            <EmployeePortalOnboarding />
        </Layout>
    );
};

export default OnboardingPage;
