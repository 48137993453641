// extracted by mini-css-extract-plugin
export var Button = "Styles-module--Button--03845";
export var Employee = "Styles-module--Employee--ba917";
export var EmployeeImage = "Styles-module--EmployeeImage--281da";
export var EmployeeInfo = "Styles-module--EmployeeInfo--9a403";
export var Header = "Styles-module--Header--0598c";
export var Input = "Styles-module--Input--e9bc4";
export var InputBlock = "Styles-module--InputBlock--f9c04";
export var Link = "Styles-module--Link--bf10c";
export var Main = "Styles-module--Main--cdf07";
export var MainInner = "Styles-module--MainInner--5431f";
export var Message = "Styles-module--Message--af9be";
export var Reset = "Styles-module--Reset--2b432";
export var Search = "Styles-module--Search--b861e";
export var half = "Styles-module--half--39f3b";
export var quarter = "Styles-module--quarter--71148";
export var third = "Styles-module--third--9c40e";