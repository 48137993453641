import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from '../../Views/Portal/Courses/Components/Button/Button';
import * as styles from './Modal.module.scss';

interface Props {
  Show: boolean;
  Close: () => void;
  Buttons: Button[];
  Size: "large" | "medium" | "small";
  Title: string;
  children?: any;
}

type Button = {
  Text: any;
  OnClick: () => void;
  Color: "Red" | "Green" | "Orange" | "Theme" | "Link" | "GreenLink" | "RedLink" | "OrangeLink";
  Disabled?: boolean;
}

export const Modal: React.FC<Props> = ({ Title, Size, Show, Close, Buttons, children }) => {
  return (<div className={`${styles.Modal} ${styles[Size]} ${Show ? styles.Show : ""}`}>
    <div className={styles.ModalBackground} onClick={() => Close()}></div>
    <div className={styles.ModalContent}>
      <div className={styles.ModalHeader}>
        <h1>{Title}</h1>
        <button type="button" onClick={() => Close()}>
          <i>
            <FontAwesomeIcon icon={faTimes} />
          </i>
        </button>
      </div>
      <div className={styles.ModalBody}>
        {children}
      </div>
      {Buttons.length ? <div className={styles.ModalFooter}>
        {
          Buttons.map((button) => {
            return <Button Disabled={button.Disabled} Type="button" OnClick={() => button.OnClick()} Color={button.Color}>{button.Text}</Button>
          })
        }
      </div> : <></>}
    </div>
  </div>);
};

